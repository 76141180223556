<template>
    <section class="no-padding-top">
        <div class="block">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-body">
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Associado</label>
                            <div class="col-sm-5">
                                <input type="text" class="form-control" v-model="nomeAssociado">
                                <!-- dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores[0].label -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Cadastro</label>
                            <div class="col-sm-3">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <button type="submit" class="btn btn-primary ml-3">Plano</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-body">
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Descrição da Verba</label>
                            <div class="col-sm-4">
                                <select name="" id="" class="form-control">
                                    <!-- trabalhar no selected para que ele selecione o id da descricao da verba -->
                                    <option v-bind:value="descricao.data" v-for="(descricao, index) in descricoes" v-bind:key="index" :selected="descricao.data==dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField12.valorDoCampoField"> {{ descricao.descricao }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Registro</label>
                            <div class="col-sm-3">
                                <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField2.valorDoCampoField">
                            </div>
                            <label for="" class="form-control-label col-sm-2">1a Cobr(mês/ano)</label>
                            <div class="col-sm-2">
                                <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField5.valorDoCampoField">
                            </div>
                            <label for="" class="form-control-label col-sm-1">Dia Vencimento</label>
                            <div class="col-sm-1">
                                <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros.fasFieldDiaVencimento.valorDoCampoField">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Empresa Conveniada</label>
                            <div class="col-sm-3">
                                <select name="" id="" class="form-control" @change="recuperaDadosPlano($event.target.value)">
                                    <option v-bind:value="planoConveniado.data" v-for="(planoConveniado, index) in planoSaudeConveniados" v-bind:key="index" :selected="planoConveniado.data==dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField13.valorDoCampoField"> {{ planoConveniado.label }}</option>
                                    
                                </select>
                            </div>
                            <label for="" class="form-control-label col-sm-1">Plano</label>
                            <div class="col-sm-3">
                                <select name="" id="" class="form-control">
                                    <option v-bind:value="plano.data" v-for="(plano, index) in planos" v-bind:key="index" :selected="plano.data==dtoDataSource.listaDeParametros.fasFieldIdPlanoSaude.valorDoCampoField"> {{ plano.descricao }}</option>
                                </select>
                            </div>
                            <label for="" class="form-control-label col-sm-1">Idade</label>
                            <div class="col-sm-1">
                                <input type="text" class="form-control" v-model="idadeAssociado">
                                <!-- dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores[0].idade -->
                            </div>
                            <label for="" class="form-control-label col-sm-1">Valor</label>
                            <div class="col-sm-1">
                                <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField7.valorDoCampoField">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="form-control-label col-sm-1">Inclusão</label>
                            <div class="col-sm-2">
                                <input type="Date" class="form-control" :value="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField9.valorDoCampoField | converteData">
                            </div>
                            <label for="" class="form-control-label col-sm-1">Exclusão</label>
                            <div class="col-sm-2">
                                <input type="Date" class="form-control" :value="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField10.valorDoCampoField | converteData">
                            </div>
                            <div class="i-checks">
                                <input type="checkbox" class="checkbox-template" name="" id="" :checked="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField11.valorDoCampoField | converteCheckbox">
                                <label>Valor Fixo</label>
                            </div>
                            <label for="" class="form-control-label col-sm-1">Marca Ótica</label>
                            <div class="col-sm-2">
                                <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASField8.valorDoCampoField">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-body">
                        <div class="form-group d-flex flex-row-reverse">
                            <button type="submit" class="btn btn-primary ml-3">Salvar</button>
                            <router-link to="/convenio-de-associados" tag="button" class="btn btn-secondary ml-3">Sair</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
    id: {
      type: String
    }
  },
  data() {
      return {
          descricoes: [],
          planoSaudeAssociados: [],
          planos: [],
          nomeAssociado: '',
          idadeAssociado: '',
          dtoDataSource: {
              banco: this.$store.state.nomeBD,
              tabela: 'planosaude_has_associado',
              idTabela: 'idPlanoSaudeAssociado',
              listaDeParametros: {
                idPlanoSaudeAssociado: {
                    nomeDoCampoField: "idPlanoSaudeAssociado",
                    valorDoCampoField: "",
                },
                _CadastroPlanoSaudeAssociadoForm_FASField5: {
                    nomeDoCampoField: 'mesAnoPrimVenc'
                },
                fasFieldDiaVencimento: {
                    nomeDoCampoField: 'diaVencimento',
                    valorDoCampoField: '0'
                },
                _CadastroPlanoSaudeAssociadoForm_FASField2: {
                    nomeDoCampoField: 'registro'
                },
                _CadastroPlanoSaudeAssociadoForm_FASComboBox4_ComboBox: {
                    banco: this.$store.state.nomeBD,
                    empresaControlada: '02',
                    idTabela: 'idEmpresaConveniada',
                    campoDescricao: 'razaoSocial',
                    sqlTemplate: 'empresasconveniadascomtabelas',
                    listaDeParametros: [

                    ]
                },
                _CadastroPlanoSaudeAssociadoForm_FASField8: {
                    nomeDoCampoField: 'marcaOtica',                    
                },
                _CadastroPlanoSaudeAssociadoForm_FASField7: {
                    nomeDoCampoField: 'valor'
                },
                _CadastroPlanoSaudeAssociadoForm_FASField9: {
                    nomeDoCampoField: 'dataInclusao'
                },
                _CadastroPlanoSaudeAssociadoForm_FASField11: {
                    nomeDoCampoField: 'valorFixo',
                    valorDoCampoField: '0'
                },
                _CadastroPlanoSaudeAssociadoForm_FASField10: {
                    nomeDoCampoField: 'dataExclusao'
                },
                fasFieldIdAssociado: {
                    nomeDoCampoField: 'idAssociado'
                },
                _CadastroPlanoSaudeAssociadoForm_FASComboBox3_ComboBox: {
                    banco: this.$store.state.nomeBD,
                    empresaControlada: '02',
                    tabela: 'verba',
                    idTabela: 'idVerba',
                    campoDescricao: 'descricao',
                    sqlTemplate: 'consultaverbasparaplano',
                    listaDeParametros: [

                    ]
                },
                _CadastroPlanoSaudeAssociadoForm_FASField12: {
                    nomeDoCampoField: 'idVerba'
                },
                fasFieldIdPlanoSaude: {
                    nomeDoCampoField: 'idPlanoSaude'
                },
                _CadastroPlanoSaudeAssociadoForm_FASField13: {
                    nomeDoCampoField: 'idEmpresaConveniada'
                }
              }
          },
          dtoConsultaPlanos: {
              listaDeParametros: {
                  fasComboBoxPlanos_ComboBox: {
                      banco: this.$store.state.nomeBD,
                      empresaControlada: '02',
                      idTabela: 'idPlanoSaude',
                      campoDescricao: 'descricao',
                      sqlTemplate: 'consultaplanosporempresa',
                      listaDeParametros: [
                          {
                              nomeDoParametro: 'idEmpresa',
                              valor: ''
                          }
                      ]
                  }
              }
          },
          dtoConsultaAssociados: {
              listaDeParametros: {
                  fasComboBoxAssociados_ComboBox: {
                      banco: this.$store.state.nomeBD,
                      empresaControlada: '02',
                      idTabela: 'idAssociado',
                      campoDescricao: 'descricao',
                      sqlTemplate: 'consultaassociadosparaplano',
                      listaDeParametros: [
                          {
                              nomeDoParametro: 'consultaAssociados',
                              valor: ''
                          }
                      ]
                  }
              }
          }

      }
  },
  mounted() {
      this.recuperaDadosConvenio(this.id)
  },
  methods: {
      recuperaDadosConvenio(idPlanoSaudeAssociado) {
          this.dtoDataSource.listaDeParametros.idPlanoSaudeAssociado.valorDoCampoField = idPlanoSaudeAssociado
          this.$store.state.loader = true
          this.$http.post("executaJava", {
              classe: "GenericoBusiness",
              metodo: "consultar",
              listaDeParametros: {
                listaDeParametros: {
                    dtoDataSource: this.dtoDataSource,
                }
              }
          })
          .then(res => {
              console.log(res)
              this.dtoDataSource.listaDeParametros = res.data.listaDeParametros.dtoDataSource.listaDeParametros
              this.descricoes = this.dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASComboBox3_ComboBox.listaDeValores
              this.planoSaudeConveniados = this.dtoDataSource.listaDeParametros._CadastroPlanoSaudeAssociadoForm_FASComboBox4_ComboBox.listaDeValores
              this.recuperaDadosAssociado(this.dtoDataSource.listaDeParametros.fasFieldIdAssociado.valorDoCampoField)
            //   Chamar esse metodo tambem quando for alterado o valor da combo.
              this.recuperaDadosPlano(this.dtoDataSource.listaDeParametros.fasFieldIdPlanoSaude.valorDoCampoField)            
              this.$store.state.loader=false
          })
          .catch((err) => {
            console.log(err)
            this.$store.state.loader=false
          })
      },
      recuperaDadosPlano(idPlano) {
        //   console.log(idPlano)
          this.dtoConsultaPlanos.listaDeParametros.fasComboBoxPlanos_ComboBox.listaDeParametros[0].valor=idPlano
        //   console.log(this.dtoConsultaPlanos)
          this.$store.state.loader = true
          this.$http.post("executaJavaComParametro", {
              classe: 'GenericoBusiness',
              metodo: 'consultar',
              listaDeParametros: {
                  listaDeParametros: {
                      dtoConsultaPlanos: this.dtoConsultaPlanos
                  }
              }
          })
          .then(res => {
              this.planos = res.data.listaDeParametros.dtoConsultaPlanos.listaDeParametros.fasComboBoxPlanos_ComboBox.listaDeValores
              this.$store.state.loader=false
              console.log(this.planos)
          })
          .catch((err) => {
            console.log(err)
            this.$store.state.loader=false
          })
      },
      recuperaDadosAssociado(idAssociado) {
          this.dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeParametros[0].valor=idAssociado
          this.$store.state.loader = true
          this.$http.post("executaJavaComParametro", {
              classe: 'GenericoBusiness',
              metodo: 'consultar',
              listaDeParametros: {
                  listaDeParametros: {
                      dtoConsultaAssociados: this.dtoConsultaAssociados
                  }
              }
          })
          .then(res => {              
              this.dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores = res.data.listaDeParametros.dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores          
              this.nomeAssociado = this.dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores[0].label
              this.idadeAssociado = this.dtoConsultaAssociados.listaDeParametros.fasComboBoxAssociados_ComboBox.listaDeValores[0].idade
              this.$store.state.loader=false
          })
          .catch((err) => {
            console.log(err)
            this.$store.state.loader=false
          })
      }

  }

}
</script>

<style>

</style>