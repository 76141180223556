<template>
  <div class="login-page">
    <div id="loader-frame" v-if="this.$store.state.loader"><div id="loader"></div></div>
      <div class="container d-flex align-items-center">
        <div class="form-holder has-shadow">
          <div class="row">
            <!-- Logo & Information Panel-->
            <div class="col-lg-6">
              <div class="info d-flex align-items-center">
                <div class="content">
                  <div class="logo">
                    <h1>ASSER</h1>
                  </div>
                  <p>Acesse sua conta</p>
                </div>
              </div>
            </div>
            <!-- Form Panel    -->
            <div class="col-lg-6">
              <div class="form d-flex align-items-center">
                <div class="content">
                  <div v-if="isError" class="alert alert-danger" role="alert">
                    Nome de usuário ou senha inválidos.
                  </div>
                  <form method="get" class="form-validate mb-4">
                    <div class="form-group">
                      <input v-model="usuario.usuario" id="login-username" type="text" placeholder="E-mail" name="loginUsername" required data-msg="Please enter your username" class="input-material">
                      <!-- <label for="login-username" class="label-material">Email</label> -->
                    </div>
                    <div class="form-group">
                      <input v-model="usuario.senha" id="login-password" type="password" maxlength="7" placeholder="Senha" name="loginPassword" required data-msg="Please enter your password" class="input-material">
                      <!-- <label for="login-password" class="label-material">Senha</label> -->
                    </div>
                    <button @click.prevent="login" type="submit" class="btn btn-primary">Login</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights text-center">
        <p>Design by <a href="https://bootstrapious.com/p/bootstrap-4-dark-admin" class="external">Bootstrapious</a></p>
        <!-- Please do not remove the backlink to us unless you support further theme's development at https://bootstrapious.com/donate. It is part of the license conditions. Thank you for understanding :)-->
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isError: false,
            usuario: {
                usuario: "",
                senha: ""
            }
        }
    },
    methods: {
        login() {
            this.$store.state.loader=true
            this.$http.post("executaJava", {
                classe:'UsuarioBusiness1',
                metodo: 'validaUsuario',
                parametro: {
                    usuario: this.usuario.usuario,
                    senha: this.usuario.senha,
                    nomeBD: this.$store.getters.nomeBD
                }
            })
            .then(res => {
                this.$store.state.loader=false
                console.log(res)
                if(res.data.validaUsuario=='erro'){
                  this.isError=true
                } else {
                  this.$store.commit('usuario', this.usuario.usuario)
                  sessionStorage.setItem("dadosUsuario", res.data)
                  this.$router.push("/home")
                }
            })
            .catch(() => (this.isError = true))
            this.usuario = {
                nome: '',
                senha: ''
            }
        }
    }

}
</script>

<style>

</style>