<template>
  <section class="no-padding-top">
      <div class="block">
          <div class="row">
              <div class="col-md-6">
                  <div class="block-body">
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Empresa Conveniada</label>
                          <div class="col-sm-8">
                            <select disabled v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeTabelaForm_FASField4.valorDoCampoField" name="" id="" class="form-control">
                                <option v-bind:value="empresaConveniada.data" v-for="(empresaConveniada, index) in listaEmpresaConveniada" v-bind:key="index"> {{ empresaConveniada.label }}</option>
                            </select>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Plano</label>
                          <div class="col-sm-8">
                              <select disabled v-model="dtoDataSource.listaDeParametros.fasFieldIdPlanoSaude.valorDoCampoField" name="" id="" class="form-control">
                                  <option v-bind:value="plano.data" v-for="(plano, index) in listaPlanos" v-bind:key="index"> {{ plano.descricao }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="form-group row">
                          <button type="submit" class="btn btn-primary ml-3">Plano</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="block">
          <div class="row">
              <div class="col-md-6">
                  <div class="block-body">
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Idade</label>
                          <div class="col-sm-4">
                              <input v-model="valorSelecionado.idade" type="text" class="form-control">
                         </div>
                      </div>
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Valor</label>
                          <div class="col-sm-4">
                              <input v-model="valorSelecionado.valor" type="text" class="form-control">
                          </div>
                      </div>
                  </div>                                      
              </div>
              <div class="col-md-6">
                  <div class="table-responsive">
                      <table class="table table-striped table-sm">
                          <thead>
                              <tr>
                                  <th>Idade Limite</th>
                                  <th>Valor R$</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(valor, index) in listaDeValores" v-bind:key="index" @click.prevent="selecionaValor(valor, index)">
                                  <td>{{ valor.idade }}</td>
                                  <td>{{ valor.valor }}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
      <div class="block">
          <div class="row">
              <div class="col-md-12">
                  <div class="block-body">
                      <div class="form-group d-flex flex-row-reverse">
                          <button type="submit" class="btn btn-primary ml-3">Salvar</button>
                          <router-link to="/tabela-de-planos" tag="button" class="btn btn-secondary ml-3">Sair</router-link>
                      </div>
                  </div>                
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            valorSelecionado: {
                index: '',
                idade: '',
                valor: '',
            },
            listaEmpresaConveniada: [],
            listaPlanos: [],
            listaDeValores: [],
            dtoDataSource: {
                banco: this.$store.state.nomeBD,
                tabela: "tabelaplanosaude",
                idTabela: "idtabelaplanosaude",
                listaDeParametros: {
                    idtabelaplanosaude: {
                        nomeDoCampoField: 'idtabelaplanosaude',
                        valorDoCampoField: ''
                    },
                    _CadastroPlanoSaudeTabelaForm_FASComboBox2_ComboBox: {
                        banco: this.$store.state.nomeBD,
                        empresaControlada: '02',
                        idTabela: 'idEmpresaConveniada',
                        campoDescricao: 'razaoSocial',
                        sqlTemplate: 'empresasconveniadas',
                        listaDeParametros: [

                        ]
                    },
                    _CadastroPlanoSaudeTabelaForm_FASField2: {
                        nomeDoCampoField: 'idade'
                    },
                    _CadastroPlanoSaudeTabelaForm_FASField3: {
                        nomeDoCampoField: 'valor'
                    },
                    _CadastroPlanoSaudeTabelaForm_FASField4: {
                        nomeDoCampoField: 'idEmpresaConveniada'
                    },
                    fasFieldIdPlanoSaude: {
                        nomeDoCampoField: 'idPlanosaude'
                    },
                }
            },
            dtoConsultaPlanos: {
                listaDeParametros: {
                    fasComboBoxPlanos_ComboBox: {
                        banco: this.$store.state.nomeBD,
                        empresaControlada: '02',
                        idTabela: 'idPlanoSaude',
                        campoDescricao: 'razaoSocial',
                        sqlTemplate: 'consultaplanosporempresa',
                        listaDeParametros: [
                            {
                                nomeDoParametro: 'idEmpresa',
                                valor: ''
                            }
                        ]
                    }
                }
            },
            dtoConsultaGrid: {
                listaDeParametros: {
                    _CadastroPlanoSaudeTabelaForm_FASGrid1: {
                        banco: this.$store.state.nomeBD,
                        empresaControlada: '02',
                        sqlTemplate: 'consultatabeladeplanodesaude',
                        listaDeParametros: [
                            {
                                nomeDoParametro: 'idPlanosaude',
                                valor: ''
                            }
                        ]
                    }
                }
            }
        }
    },
    mounted() {
        this.recuperaDadosEmpresa(this.id)
    },
    methods: {
        recuperaDadosEmpresa(idEmpresa) {
            this.dtoDataSource.listaDeParametros.idtabelaplanosaude.valorDoCampoField = idEmpresa
            this.$store.state.loader = true
            this.$http.post("executaJava", {
                classe: "GenericoBusiness",
                metodo: "consultar",
                listaDeParametros: {
                    listaDeParametros: {
                        dtoDataSource: this.dtoDataSource
                    }
                }
            })
            .then(res => {
                console.log(res)
                this.dtoDataSource.listaDeParametros = res.data.listaDeParametros.dtoDataSource.listaDeParametros
                this.listaEmpresaConveniada = this.dtoDataSource.listaDeParametros._CadastroPlanoSaudeTabelaForm_FASComboBox2_ComboBox.listaDeValores
                this.recuperaDadosPlano(this.dtoDataSource.listaDeParametros._CadastroPlanoSaudeTabelaForm_FASField4.valorDoCampoField)
            })
            .catch((err) => {
                (console.log(err))
            })
        },
        recuperaDadosPlano(idPlano) {
            this.dtoConsultaPlanos.listaDeParametros.fasComboBoxPlanos_ComboBox.listaDeParametros[0].valor=idPlano
            this.$http.post("executaJavaComParametro", {
                classe: "GenericoBusiness",
                metodo: "consultar",
                listaDeParametros: {
                    listaDeParametros: {
                        dtoConsultaPlanos: this.dtoConsultaPlanos
                    }
                }
            })
            .then(res => {
                console.log(res)
                this.dtoConsultaPlanos.listaDeParametros = res.data.listaDeParametros.dtoConsultaPlanos.listaDeParametros
                this.listaPlanos = this.dtoConsultaPlanos.listaDeParametros.fasComboBoxPlanos_ComboBox.listaDeValores
                this.recuperaDadosGridPlano(idPlano)
            })
            .catch((err) => {
                (console.log(err))
            })
        },
        recuperaDadosGridPlano(idPlano) {
            this.dtoConsultaGrid.listaDeParametros._CadastroPlanoSaudeTabelaForm_FASGrid1.listaDeParametros[0].valor=idPlano
            this.$http.post("executaJavaComParametro", {
                classe: "GenericoBusiness",
                metodo: "consultar",
                listaDeParametros: {
                    listaDeParametros: {
                        dtoConsultaGrid: this.dtoConsultaGrid
                    }
                }
            })
            .then(res => {
                console.log(res)
                this.dtoConsultaGrid.listaDeParametros = res.data.listaDeParametros.dtoConsultaGrid.listaDeParametros
                this.listaDeValores = this.dtoConsultaGrid.listaDeParametros._CadastroPlanoSaudeTabelaForm_FASGrid1.listaDeValores
                console.log(this.listaDeValores)
                this.$store.state.loader=false
            })
            .catch((err) => {
                (console.log(err))
                this.$store.state.loader=false
            })
        },
        selecionaValor(valor) {
               this.valorSelecionado = valor
        },
    }

}
</script>

<style>

</style>