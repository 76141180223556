import Vue from 'vue'
import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:8080/GAS-Web/service/javaflex'
axios.defaults.baseURL = 'https://acmebrasil.com.br/GAS-Web/service/javaflex'

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios
  }
})