<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <!-- <RegistrarPesagem /> -->
      </div>
    </div>
  </div> 
</template>

<script>


export default {
  
}
</script>

<style>

</style>