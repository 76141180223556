<template>
  <div class="block">
    <div class="title">
      <strong>{{ titulo }}</strong>
      <div class="d-flex flex-row-reverse">
        <div class="input-group">
          <input type="text" class="form-control" v-model="pFiltro" @keyup.enter="recuperaDados">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" @click.prevent="recuperaDados">Buscar</button>
          </div>
          <button type="button" class="btn btn-secondary ml-3" @click.prevent="abreFormulario(' ')">Novo</button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th
              v-for="coluna in colunas"
              :key="coluna.nome"
              v-show="coluna.exibir == 0 ? false : true"
            >
              {{ coluna.nome }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="linha in linhas" :key="linha['col' + linhas.length - 1]" @dblclick.prevent="abreFormulario(linha)">
            <td
              v-for="(item, index) in linha"
              :key="item['col' + item.length - 1]"
            >
              {{ linha[index] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="rodape row">
      <div class="form-group paginador col-md-6">
        <p>{{ primeiroRegistro }} até {{ ultimoRegistro }} de {{ totalDeRegistrosDaBusca }}</p>
      </div> 
      <div class="form-group paginador col-md-6 d-flex justify-content-end">
        <a @click.prevent="recuaPagina">Página Anterior</a><span class="pagina-corrente">{{ pagina }}</span><a @click.prevent="avancaPagina">Próxima Página</a>
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  props: {
    idOpcoesDeMenu: {
      type: String,
      required: true
    },
    formSecundario: {
      type: String,
      required: true
    },
    titulo: {
      type: String,
      required: true
    },
  },
  created() {
    this.recuperaDados()
  },
  data() {
      return {
          colunas: [],
          linhas: [],
          pSegue: '60',
          pInicio: '0',
          pagina: '1',
          pAnterior: '0',
          totalDeRegistrosDaBusca: '0',
          primeiroRegistro: '1',
          ultimoRegistro: '60',
          pFiltro: '',
      }
  },
  methods: {
      recuperaDados() {
        this.$store.state.loader=true
        this.$http
      .post("executaJava", {
        classe: "GridCadastroBusiness",
        metodo: "recuperaListaGridCad",
        parametro: {
            pVerbasPermitidas: '""',
            empresaControlada: '02',
            orderBy: '1',
            pSegue: this.pSegue,
            idOpcoesDeMenu: this.idOpcoesDeMenu,
            orderDesc: '',
            pInicio: String(this.pInicio),
            pFiltro: String(this.pFiltro),
        },
      })
      .then((res) => {
        console.log(res)
        this.montaColunas(res.data.recuperaListaGridCad)
        this.montaLinhas(res.data.recuperaListaGridCad)
        this.totalDeRegistrosDaBusca = res.data.totalDeRegistrosDaBusca
        let linhaFinal =  this.pInicio
        linhaFinal = Number(linhaFinal+60)
        this.primeiroRegistro = Number(this.pInicio+1)
        this.ultimoRegistro = linhaFinal
        this.$store.state.loader=false
      })
      .catch((err) => {
          (console.log(err))
          this.$store.state.loader=false
      })
      },
      montaColunas(data) {
        this.colunas = []
          let linhaColunas = data.split('<>')[0].split('&')
          let coluna = {}
          let i = 0;
          linhaColunas.forEach(el => {
              coluna = {}
              coluna.nome = el.split('^')[0].replace('#','')
              coluna.exibir = el.split('^')[2]
              coluna.data = 'col'+i
              i++
              this.colunas.push(coluna)
          })
      },
      montaLinhas(data) {
        this.linhas = []
          let arrLinha = data.split('<>').slice(1)
          let linha = {}
          let item = {}
          arrLinha.forEach(el => {
              item = {}
              linha = {}
              linha = el.split('^')
              for (let index = 0; index < linha.length; index++) {
                item['col'+index] = linha[index]
              }
              this.linhas.push(item)
          })
      },
      avancaPagina() {
        this.pagina++
        let linhaFinal =  this.pInicio
        linhaFinal = Number(linhaFinal+59)
        this.pInicio = linhaFinal
        this.recuperaDados()
      },
      recuaPagina() {
        this.pagina--
        if(this.pagina<='1') {
          this.pagina='1'
          this.pInicio='0'
        } else {
          this.pInicio = Number(this.pInicio-59)
        }
        this.recuperaDados()  
      },
      abreFormulario(id) {
        let aux = Object.keys(id).pop()
        id=id[aux]
        this.$router.push("/"+this.formSecundario+"/"+id)
      }
  }
};
</script>

<style>
.table-responsive {
  overflow: scroll;
  max-height: 65vh;
}
::-webkit-scrollbar {
  height: 4px;
  width: 10px;
  border: 1px solid #343a40;
}
.pagina-corrente {
  padding-left: 5px;
  padding-right: 5px;
}
.paginador a {
  cursor: pointer;
}
</style>