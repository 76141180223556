<template>
    <div class="col-lg-12">
        <Tabela idOpcoesDeMenu="010205" formSecundario="form-cadastro-empresa-fornecedora" titulo="Lista de Empresas Fornecedoras"/>
    </div>
  
</template>

<script>
import Tabela from "@/components/widgets/Tabela"
export default {
    components: { Tabela }
}
</script>

<style>

</style>