<template>
    <div class="col-lg-12">
        <Tabela idOpcoesDeMenu="010301" formSecundario="form-boleto-associado" titulo="Lista de Associados"/>
    </div>
  
</template>

<script>
import Tabela from "@/components/widgets/Tabela"
export default {
    components: { Tabela }
}
</script>

<style>

</style>