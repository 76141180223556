<template>
  <section class="no-padding-top">
      <div class="block">
          <div class="row">
              <div class="col-md-6">
                  <div class="block-body">
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Empresa Conveniada</label>
                          <div class="col-sm-8">
                              <select disabled v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeForm_FASField2.valorDoCampoField" name="" id="" class="form-control">
                                  <option v-bind:value="empresaConveniada.data" v-for="(empresaConveniada, index) in listaEmpresaConveniada" v-bind:key="index"> {{ empresaConveniada.label }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="" class="form-control-label col-sm-4">Nome do Plano</label>
                          <div class="col-sm-8">
                              <input v-model="dtoDataSource.listaDeParametros._CadastroPlanoSaudeForm_FASField1.valorDoCampoField" class="form-control" type="text">
                          </div>
                      </div>
                      <div class="form-group row d-flex flex-row-reverse">
                          <button type="submit" class="btn btn-primary ml-3">Salvar</button>
                          <router-link to="/descricao-de-planos" tag="button" class="btn btn-secondary ml-3">Sair</router-link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            listaEmpresaConveniada: [],
            dtoDataSource: {
                banco: this.$store.state.nomeBD,
                empresaControlada: "02",
                idUsuario: "8",
                tabela: "planosaude",
                idTabela: "idplanosaude",
                listaDeParametros: {
                    idplanosaude: {
                        nomeDoCampoField: "idplanosaude",
                        valorDoCampoField: "",
                    },
                    _CadastroPlanoSaudeForm_FASComboBox1_ComboBox: {
                        banco: this.$store.state.nomeBD,
                        empresaControlada: "02",
                        idTabela: "idEmpresaConveniada",
                        campoDescricao: "razaoSocial",
                        sqlTemplate: "empresasconveniadas",
                        listaDeParametros: [

                        ]
                    },
                    _CadastroPlanoSaudeForm_FASField1: {
                        nomeDoCampoField: "descricao"
                    },
                    _CadastroPlanoSaudeForm_FASField2: {
                        nomeDoCampoField: "idEmpresaConveniada"
                    }
                }
            }
        }
    },
    mounted() {
        this.recuperaDadosDescricao(this.id)
    },
    methods: {
        recuperaDadosDescricao(idDescricao) {
            this.dtoDataSource.listaDeParametros.idplanosaude.valorDoCampoField = idDescricao
            this.$store.state.loader = true
            this.$http.post("executaJava", {
                classe: "GenericoBusiness",
                metodo: "consultar",
                listaDeParametros: {
                    listaDeParametros: {
                        dtoDataSource: this.dtoDataSource
                    }
                }
            })
            .then(res => {
                console.log(res)
                this.dtoDataSource.listaDeParametros = res.data.listaDeParametros.dtoDataSource.listaDeParametros
                this.listaEmpresaConveniada = this.dtoDataSource.listaDeParametros._CadastroPlanoSaudeForm_FASComboBox1_ComboBox.listaDeValores
                this.$store.state.loader=false
            })
            .catch((err) => {
                (console.log(err))
                this.$store.state.loader=false
            })
        }
    }

}
</script>

<style>

</style>