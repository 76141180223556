<template>   
    <section class="no-padding-top">
        <div class="block">
            <div class="row">
                <div class="col-md-6">
                    <div class="block-body">
                       <h3>Vencimento dia {{ dataVencimento }}</h3>
                       <div class="form-group row">                           
                           <label class="form-control-label col-sm-4" for="">Forma de Envio</label> 
                           <div class="formas-de-envio">
                               <div class="i-checks">
                                    <input :checked="dtoConfig.listaDeParametros.fasFieldImprimir.valorDoCampoField | converteCheckbox" class="checkbox-template" type="checkbox">
                                    <label>Imprimir</label>
                                </div>
                                <div class="i-checks">
                                    <input :checked="dtoConfig.listaDeParametros.fasFieldDebito.valorDoCampoField | converteCheckbox" class="checkbox-template" type="checkbox">
                                    <label>Débito Automático</label>
                                </div>
                                <div class="i-checks">
                                    <input :checked="dtoConfig.listaDeParametros.fasFieldEmail.valorDoCampoField | converteCheckbox" class="checkbox-template" type="checkbox">
                                    <label>Enviar por E-mail</label>
                                </div>
                           </div>   
                       </div> 
                       <div class="table-responsive">
                           <table class="table table-striped table-sm">
                               <thead>
                                   <tr>
                                       <th>Descrição</th>
                                        <th>Valor R$</th>
                                   </tr>                                   
                               </thead>
                               <tbody>
                                   <tr v-for="(valor, index) in valores" v-bind:key="index">
                                       <td>{{ valor.descricao }}</td>
                                       <td>{{ valor.valor }}</td>
                                   </tr>
                               </tbody>
                           </table>
                       </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="block-body">
                        <div class="form-group row">
                            <label class="form-control-label col-sm-4" for="">Descrição</label>          
                            <div class="col-sm-8">
                                <select class="form-control" name="" id="">
                                    <option v-bind:value="descricao.data" v-for="(descricao, index) in descricoes" v-bind:key="index"> {{ descricao.descricao }}</option>
                                </select>
                            </div>                            
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-sm-4" for="">1ª Cobrança (mês e ano)</label>
                            <div class="col-sm-4">
                                <input class="form-control" type="text">
                            </div>                            
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-sm-4" for="">Dia de Vencimento</label>
                            <div class="col-sm-4">
                                <input class="form-control" type="number">
                            </div>                            
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-sm-4" for="">Período</label>          
                            <div class="col-sm-4">
                                <select class="form-control" name="" id="">
                                    <option value="">Mensal</option>
                                    <option value="">Trimestral</option>
                                    <option value="">Semestral</option>
                                    <option value="">Anual</option>
                                </select>
                            </div>                            
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-sm-4" for="">Valor(R$)</label>
                            <div class="col-sm-4">
                                <input class="form-control" type="text">
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    props: {
    id: {
      type: String,
    },
  },
  data() {
      return {
          modelDtoValores: {},
          dataVencimento: '',
          valores: [],
          descricoes: [
              {
                  data: "1",
                  idVerba: "1",
                  label: "MENS. - F/E",
                  descricao: "MENS. - F/E",
              },
              {
                  data: "2",
                  idVerba: "2",
                  label: "SEGURO DIT",
                  descricao: "SEGURO DIT",
              },
              {
                  data: "3",
                  idVerba: "3",
                  label: "SEG. ACID. PESSOAIS",
                  descricao: "SEG. ACID. PESSOAIS",
              },
              {
                  data: "6",
                  idVerba: "6",
                  label: "Mensalidade Associado",
                  descricao: "Mensalidade Associado",
              },
              {
                  data: "7",
                  idVerba: "7",
                  label: "Plano Dental",
                  descricao: "Plano Dental",
              },
          ],
          dtoConfig: {
              banco: this.$store.state.nomeBD,
              sqlTemplate: "consultaconfigparavaloresdaverba",
              listaDeParametros: {
                fasParametroIdAssociado:{
                  nomeDoParametro:"idAssociado",
                  valor:''
               },
               fasFieldDiaVencimento:{
                  nomeDoCampoField:"diaVencimento",
                  valorDoCampoField:''
               },
            //    "null":"0",
               fasFieldIdAssociado:{
                  nomeDoCampoField:"idAssociado",
                  valorDoCampoField:''
               },
               fasFieldEmail:{
                  nomeDoCampoField:"email",
                  valorDoCampoField:"0"
               },
               fasParametroDiaVencimento:{
                  nomeDoParametro:"diaVencimento",
                  valor:''
               },
               fasFieldDebito:{
                  nomeDoCampoField:"debito",
                  valorDoCampoField:"0"
               },
               fasFieldImprimir:{
                  nomeDoCampoField:"imprimir",
                  valorDoCampoField:"0"
               }
             }
          }
        
      }
  },
  mounted() {
      this.recuperaDadosValores(this.id)
  },
  methods: {
      recuperaDadosValores(idAssociado) {
        console.log(idAssociado)
          this.$store.state.loader = true
          this.$http
            .post("executaJavaParametroSimples", {
                classe: "ManterValorBusiness",
                metodo: "consultarParaCadastroDeValores",
                listaDeParametros: {
                    listaDeParametros: {
                        idAssociado: idAssociado,
                        banco: this.$store.getters.nomeBD,
                        _nomeBD: this.$store.getters.nomeBD
                    }
                },
            })
            .then(res => {
                console.log(res)
                this.dataVencimento = Object.keys(res.data.listaDeParametros)[0]
                this.valores = res.data.listaDeParametros[this.dataVencimento]
                console.log(res.data.listaDeParametros[this.dataVencimento])
                this.recuperaDadosConfigValores(idAssociado)
            })
            .catch((err) => {
                (console.log(err))
                this.$store.state.loader=false
            })
        
      },
      recuperaDadosConfigValores(idAssociado) {
          this.dtoConfig.listaDeParametros.fasParametroIdAssociado.valor = idAssociado
          this.dtoConfig.listaDeParametros.fasFieldIdAssociado.valorDoCampoField = idAssociado
          this.dtoConfig.listaDeParametros.fasParametroDiaVencimento.valor = this.dataVencimento
          this.dtoConfig.listaDeParametros.fasFieldDiaVencimento.valorDoCampoField = this.dataVencimento
          this.$http
            .post("executaJava", {
                classe: "ManterValorBusiness",
                metodo: "consultar",
                listaDeParametros: {
                    listaDeParametros: {
                        dtoConfig: this.dtoConfig
                    }
                }
            })
            .then(res => {
                console.log(res)
                this.dtoConfig.listaDeParametros = res.data.listaDeParametros.dtoConfig.listaDeParametros
                this.$store.state.loader = false
            })
            .catch((err) => {
                (console.log(err))
                this.$store.state.loader=false
            })
      }
  }

}
</script>

<style>

</style>