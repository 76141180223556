import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/views/Login'
import Home from '@/components/views/Home'
import CadastroAssociados from '@/components/views/CadastroAssociados'
import CadastroValores from '@/components/views/CadastroValores'
import FormCadastroAssociados from '@/components/views/FormCadastroAssociados'
import FormCadastroValores from '@/components/views/FormCadastroValores'
import DescricaoPlanos from '@/components/views/DescricaoPlanos'
import FormDescricaoPlano from '@/components/views/FormDescricaoPlano'
import TabelaPlanos from '@/components/views/TabelaPlanos'
import FormTabelaPlano from '@/components/views/FormTabelaPlano'
import ConvenioAssociados from '@/components/views/ConvenioAssociados'
import EmpresasFornecedoras from '@/components/views/EmpresasFornecedoras'
import FormConvenioAssociado from '@/components/views/FormConvenioAssociado'
import FormEmpresaFornecedora from '@/components/views/FormEmpresaFornecedora'
import TotalParticipantes from '@/components/views/TotalParticipantes'

import RemessaPlanos from '@/components/views/RemessaPlanos'
import FormRemessaDescricaoPlano from '@/components/views/FormRemessaDescricaoPlano'

import FormBoletoAssociado from '@/components/views/FormBoletoAssociado'
import BoletoAssociado from '@/components/views/BoletoAssociado'

Vue.use(Router)

const Dashboard = () => import( /* webpackChunckName : dashboard*/'@/components/views/Dashboard')

const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Dashboard,
            children: 
            [
                {
                    path: 'home',
                    component: Home,
                    name: 'home',
                    meta:
                    {
                        titulo: 'Home'
                    }
                },
                {
                    path: 'cadastro-associados',
                    component: CadastroAssociados,
                    name: 'CadastroAssociados',
                    meta:
                    {
                        titulo: 'Cadastro de Associados'
                    }
                },
                {
                    path: 'cadastro-valores',
                    component: CadastroValores,
                    name: 'CadastroValores',
                    meta:
                    {
                        titulo: 'Cadastro de Valores'
                    }
                },
                {
                    path: 'form-cadastro-associados/:id',
                    component: FormCadastroAssociados,
                    name: 'FormCadastroAssociados',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Associados'
                    }
                },
                {
                    path: 'form-cadastro-valores/:id',
                    component: FormCadastroValores,
                    name: 'FormCadastroValores',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Valores'
                    }
                },
                {
                    path: 'descricao-de-planos',
                    component: DescricaoPlanos,
                    name: 'DescricaoPlanos',
                    meta:
                    {
                        titulo: 'Cadastro de Descrição de Planos'
                    }
                },
                {
                    path: 'form-cadastro-descricao-de-plano/:id',
                    component: FormDescricaoPlano,
                    name: 'FormDescricaoPlano',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Descrição de Planos'
                    }
                },
                {
                    path: 'tabela-de-planos',
                    component: TabelaPlanos,
                    name: 'TabelaPlanos',
                    meta:
                    {
                        titulo: 'Cadastro de Tabelas de Planos'
                    }
                },
                {
                    path: 'empresas-fornecedoras',
                    component: EmpresasFornecedoras,
                    name: 'EmpresasFornecedoras',
                    meta:
                    {
                        titulo: 'Cadastro de Empresas Fornecedoras'
                    }
                },
                {
                    path: 'form-cadastro-empresa-fornecedora/:id',
                    component: FormEmpresaFornecedora,
                    name: 'FormEmpresaFornecedora',
                    props: true,
                    meta:
                    {
                        titulo: 'Cadastro de Empresas Fornecedoras'
                    }
                },
                {
                    path: 'form-cadastro-tabela-de-plano/:id',
                    component: FormTabelaPlano,
                    name: 'FormTabelaPlano',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Tabela de Planos'
                    }
                },
                {
                    path: 'convenio-de-associados',
                    component: ConvenioAssociados,
                    name: 'ConvenioAssociados',
                    meta:
                    {
                        titulo: 'Cadastro de Convênio do Associado'
                    }
                },
                {
                    path: 'form-cadastro-convenio-de-associado/:id',
                    component: FormConvenioAssociado,
                    name: 'FormConvenioAssociado',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Convênio do Associado'
                    }
                },
                {
                    path: 'total-de-participantes',
                    component: TotalParticipantes,
                    name: 'TotalParticipantes',
                    meta:
                    {
                        titulo: 'Cadastro de Total de Participantes'
                    }
                },
                {
                    path: 'lista-planos-remessa',
                    component: RemessaPlanos,
                    name: 'RemessaPlanos',
                    meta:
                    {
                        titulo: 'Lista de PLanos'
                    }
                },
                {
                    path: 'form-remessa-cadastro-descricao-de-plano/:id',
                    component: FormRemessaDescricaoPlano,
                    name: 'FormRemessaDescricaoPlano',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de Cadastro de Descrição de Planos'
                    }
                },
                {
                    path: 'boleto-associados',
                    component: BoletoAssociado,
                    name: 'BoletoAssociado',
                    meta:
                    {
                        titulo: 'Lista de Associados'
                    }
                },
                {
                    path: 'form-boleto-associado/:id',
                    component: FormBoletoAssociado,
                    name: 'FormBoletoAssociado',
                    props: true,
                    meta:
                    {
                        titulo: 'Formulário de criação de boletos individuais'
                    }
                },
            ],
            meta: {
                authRequired: true
            }
        },
        {
            path: '/login',
            component: Login
        }
    ]
})

router.beforeEach((to, from, next) => {
    to.meta.authRequired && !sessionStorage.getItem('dadosUsuario') ? next('/login') : next()
})


export default router