import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isShrinked: false,
        usuario: {},
        // nomeBD: 'asserorg_gas',
        nomeBD: 'pw4acmeb_asser',
        // nomeBD: 'pw4acmeb_asser_hml',
        loader: false,
    },
    getters: {
        usuario(state) {
            return state.usuario
        },
        nomeBD(state) {
            return state.nomeBD
        },
        isShrinked(state) {
            return state.isShrinked
        },
        loader(state) {
            return state.loader
        },
    },
    mutations: {
        usuario(state, payload) {
            state.usuario = payload
        },
    }
})