<template>
  <section class="no-padding-top">
      <div class="block">
          <div class="row">
            <div class="col-md-12">
              <div class="block-body">
                <div class="form-group row">
                  <label for="" class="form-control-label col-sm-1">Pessoa</label>
                  <div class="col-sm-4">
                    <select name="" id="" class="form-control">
                      <option value="">Jurídica</option>
                    </select>
                  </div>
                  <label for="" class="form-control-label col-sm-2">Tipo de Empresa</label>
                  <div class="col-sm-4">
                    <select name="" id="" class="form-control">
                      <option value="">Fornecedora</option>
                      <option value="">Conveniada</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="form-control-label col-sm-2">Nome Reduzido</label>
                    <div class="col-sm-3">
                      <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField1.valorDoCampoField">
                    </div>
                </div>
                <div class="form-group row">
                  <label for="" class="form-control-label col-sm-2">Razão Social</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField2.valorDoCampoField">
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="block">
        <div class="row">
          <div class="col-md-12">
            <div class="block-body">
              <div class="form-group row">
                <label for="" class="form-control-label col-sm-1">CEP</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField5.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Endereço</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField3.valorDoCampoField">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="form-control-label col-sm-1">Bairro</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField4.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Cidade</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField6.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Estado</label>
                <div class="col-sm-1">
                  <!-- Verificar o campo v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASComboBox2.valorDoCampoField" -->
                  <select name="" id="" class="form-control">
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="row">
          <div class="col-md-12">
            <div class="block-body">
              <div class="form-group row">
                <label for="" class="form-control-label col-sm-1">Tel 1</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField8.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Tel 2</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField9.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Inscrição</label>
                <div class="col-sm-2">
                  <select name="" id="" class="form-control">
                    <option value="">Estadual</option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <input type="text" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="form-control-label col-sm-1">Contato</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField12.valorDoCampoField">
                </div>
                <label for="" class="form-control-label col-sm-1">Celular</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model="dtoDataSource.listaDeParametros._CadastroEmpresasForm_FASField13.valorDoCampoField">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-body">
                        <div class="form-group d-flex flex-row-reverse">
                            <button type="submit" class="btn btn-primary ml-3">Salvar</button>
                            <router-link to="/empresas-fornecedoras" tag="button" class="btn btn-secondary ml-3">Sair</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      dtoDataSource: {
        banco: this.$store.state.nomeBD,
        empresaControlada: "02",
        idUsuario: "8",
        tabela: "dadosempresa",
        idTabela: "idDadosEmpresa",
        listaDeParametros: {
          idDadosEmpresa: {
            nomeDoCampoField: "idDadosEmpresa",
            valorDoCampoField: "",
          },
          _CadastroEmpresasForm_FASField8:{
            nomeDoCampoField: "telefone"
          },
          _CadastroEmpresasForm_FASField7:{
            nomeDoCampoField: "pais",
            valorDoCampoField: "BRASIL"
          },
          _CadastroEmpresasForm_FASField9:{
            nomeDoCampoField: "telefone1"
          },
          _CadastroEmpresasForm_FASField4:{
            nomeDoCampoField:"bairro"
          },
          _CadastroEmpresasForm_FASField3:{
            nomeDoCampoField:"endereco"
          },
          _CadastroEmpresasForm_FASField6:{
            nomeDoCampoField:"cidade"
          },
          _CadastroEmpresasForm_FASField5:{
            nomeDoCampoField:"cep"
          },
          _CadastroEmpresasForm_FASField2:{
            nomeDoCampoField:"razaoSocial"
          },
          _CadastroEmpresasForm_FASField1:{
            nomeDoCampoField:"nomeReduzido"
          },
          _CadastroEmpresasForm_FASComboBox1:{
            nomeDoCampoField:"juridicaFisica",
            valorDoCampoField:"1"
          },
          _CadastroEmpresasForm_FASComboBox2:{
            nomeDoCampoField:"estado",
              valorDoCampoField:"RJ"
          },
          _CadastroEmpresasForm_FASComboBox3:{
            nomeDoCampoField:"tpInscricao",
            valorDoCampoField:"estadual"
          },
          _CadastroEmpresasForm_FASField11:{
            nomeDoCampoField:"inscricao"
          },
          _CadastroEmpresasForm_FASField10:{
            nomeDoCampoField:"CNPJ"
          },
          _CadastroEmpresasForm_FASField13:{
            nomeDoCampoField:"celContato"
          },
          _CadastroEmpresasForm_FASField12:{
            nomeDoCampoField:"contato"
          }
        }
      },
      dtoTipoEmpresa: {
        banco: this.$store.state.nomeBD,
        empresaControlada:"02",
        idUsuario:"8",
        tabela:"empresaconveniada",
        idTabela:"idDadosEmpresa",
        tabelaMaster:"dadosempresa",
        idTabelaMaster:"idDadosEmpresa",
        listaDeParametros:{
          idDadosEmpresa: {
            nomeDoCampoField: "idDadosEmpresa",
            valorDoCampoField: "",
          },
          _CadastroEmpresasForm_FASGroupRadioButton1:{
            nomeDoCampoField:"empresaConvForn",
            valorDoCampoField:"F"
          },
            idEmpresaEspecifica:{
            nomeDoCampoField:"idEmpresaConveniada"
          }
        }
      }
    }
  },
  mounted() {
    this.recuperaDadosEmpresaConveniada(this.id)
  },
  methods: {
    recuperaDadosEmpresaConveniada(idEmpresaConveniada) {
      this.dtoDataSource.listaDeParametros.idDadosEmpresa.valorDoCampoField = idEmpresaConveniada
      this.dtoTipoEmpresa.listaDeParametros.idDadosEmpresa.valorDoCampoField = idEmpresaConveniada
      this.$store.state.loader = true
      this.$http.post("executaJava", {
        classe: "GenericoBusiness",
        metodo: "consultar",
        listaDeParametros: {
          listaDeParametros: {
            dtoDataSource: this.dtoDataSource,
            dtoTipoEmpresa: this.dtoTipoEmpresa
          }
        }
      })
      .then(res => {
        console.log('resposta')
        console.log(res)
        this.dtoDataSource.listaDeParametros = res.data.listaDeParametros.dtoDataSource.listaDeParametros
        this.dtoTipoEmpresa.listaDeParametros = res.data.listaDeParametros.dtoTipoEmpresa.listaDeParametros
        this.$store.state.loader=false
        // console.log(this.dtoTipoEmpresa)
        // console.log(this.dtoDataSource)
      })
      .catch((err) => {
        console.log(err)
        this.$store.state.loader=false
      })
    }
  }


}
</script>

<style>

</style>