import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './plugins/store'
import moment from 'moment'

import './plugins/bootstrap'
import './plugins/theme'
import './plugins/axios'

Vue.config.productionTip = false

Vue.filter('formataData', (value) => {
  return moment(String(value.split('T')[0])).format('DD/MM/YYYY')
})

Vue.filter('converteData', (value) => {
  if(value) {
    return value.split(' ')[0]
  } else {
    return ''
  }
})

Vue.filter('converteCheckbox', (value) => {
  switch (value) {
    case '0':
      return false
    case '1':
      return true
    case 'false':
      return '0'
    case 'true':
      return '1'
  }
})

new Vue({
  store,
  router,
  moment,
  render: h => h(App)
}).$mount('#app')
